import { Component, Vue, Prop } from 'vue-property-decorator'
import {
  Icons
} from '@/components/base';
  
@Component({
  components: {
    Icons
  }
})
export default class ShowPath extends Vue {
  @Prop({default: []}) path!: Array<any>;

  goTo(url: string): void{
    if(url != this.$route.fullPath){
      this.$router.push(url);
    }
  }

  // this.path.length
} 
  