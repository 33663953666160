import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import content from '@/services/content';
import ShowPath from '@/components/showPath/showPath.vue';
import {} from '@/components/base';
  
@Component({
  components: {
    ShowPath
  }
})
export default class ContentWPGeneric extends Vue {
  // pagina, boletim or noticia
  @Prop({default: 'pages'}) type!: string;
  @Prop({required: true}) slug!: string;

  title = '';
  content = '';
  path: any = [];
  isLoading = false;

  mounted() {
    this.sendGetContent(this.mountUrl(this.slug))
  }

  mountUrl(slug: string): string{
    return `/${this.type}?slug=${slug}`;
  }

  sendGetContent(url: string): void{
    this.title = '';
    this.content = '';
    this.isLoading = true;
    content.sendGetContentWP(url)
      .then( (response) => {
        if(response.data.length != 0){
          this.setContent(response.data);
        }
        else{
          // console.log(window.location.href+'/../static/404');
          this.$router.replace('/pagina-nao-encontrada')
          // window.location.href = '/static/404';
          // window.location.replace(window.location.href+'/static/404');
        }
      })
      .finally( () => this.isLoading = false );
  }

  setContent(data: any): void{
    this.content = content.ajustContentImgLink(data[0].content.rendered);
    // this.content = data[0].content.rendered;
    this.title = data[0].title.rendered;
    // this.path = [
    //   { title: 'Início', to: '/'},
    //   { title: this.title},
    // ];
    // if(this.type == 'posts'){
    //   this.path = [
    //     { title: 'Início', to: '/'},
    //     { title: 'Notícias', to: '/noticias'},
    //     { title: this.title},
    //   ];
    // }
    // if(this.type == 'pages'){
    //   this.path = [
    //     { title: 'Início', to: '/'},
    //     { title: this.title},
    //   ];
    // }
    // else{
    //   this.path = [
    //     { title: 'Início', to: '/'},
    //     { title: this.type, to: '/'+this.type},
    //     { title: this.title},
    //   ];
    // }
  }

  @Watch('slug', { 
    immediate: false, deep: false 
  })
  slugChange(newVal: string) {
    this.sendGetContent(this.mountUrl(newVal))
  }

  @Watch('type', { 
    immediate: false, deep: false 
  })
  metaChange() {
    this.sendGetContent(this.mountUrl(this.slug))
  }

}