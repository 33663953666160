import { Component, Vue } from 'vue-property-decorator'
import Layout from '@/layouts/basic/basic.vue';
import ContentWPGeneric from '@/components/contentWPGeneric/contentWPGeneric.vue';
import {} from '@/components/base';
  
@Component({
  components: {
    Layout,
    ContentWPGeneric
  }
})
export default class PaginaGenerica extends Vue {
  
  get type(){
    const numPath = this.$route.path.split('/').filter( value => value != '').length;
    if(numPath == 1){
      return 'pages'
    }
    else if(numPath == 2){
      return 'posts'
    }
    if(numPath == 3){
      return this.$route.params.type;
    }
    return this.getNumRoutesPath();
  }

  getNumRoutesPath(){
    return this.$route.path.split('/').filter( value => value != '').length;
  }
  
}
  